<template>
  <div style="background: black;">
    <template v-if="d_fastAnalyzeModeShow">
      <polestar-fast-analyze :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children" :p_mode="'talk'"></polestar-fast-analyze>
    </template>
  </div>
</template>

<script>
import OptionService from '@/services/option';
import GlobalService from '@/services/global';
import {
  default as PolestarFastAnalyze
} from "@/components/widgets/PolestarFastAnalyze";
import { mapGetters } from 'vuex';
export default {
  name: 'WaimTalk',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    PolestarFastAnalyze
  },
  props: {},
  data () {
    return {
      mother_children: { 'forceUpdate': 0, 'send_table_to_article': 0, 'article_naming': 0 },
      d_fastAnalyzeModeShow: false,
      d_searchWisdomEraAiRegion: '',
      d_waimList: [],
      d_selectedProjectData: {},
      column_list: [],
      option_data: {}
    };
  },
  created: function () {
    let query = 'data_type=wdm3&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm3'] = resp.data.result;
          let query = 'data_type=wdm72&location=wisdom';
          OptionService.get_last_wdm_of_this_type(query)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.option_data['wdm72'] = resp.data.result;
                if (this.$route.query.waim_id) {
                  this.f_getProjectData(this.$route.query.waim_id);
                }
              } else {
                console.log('errror', resp.data.message);
              }
            });
        } else {
          console.log('errror', resp.data.message);
        }
      });
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_getProjectData: function (wdm3_id) {
      let key = 'wdm3_' + wdm3_id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Modeliniz getiriliyor. Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': key, 'bucket': 'polestar' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result;
            if (this.d_selectedProjectData.design) {
              this.column_list = this.d_selectedProjectData.design.column_list;
            }
            this.d_fastAnalyzeModeShow = true;
          } else {
            alert('error', resp.data.message);
          }
        });
    }
  },
  watch: {}
};

</script>

